
.site-header {
  width: 100%;
  
  @include media_query(1000px) {
    height: 110px;
    
    display: flex;
    flex-direction: row;

    border: solid 1px;
    border-radius: 0px 0px 40px 40px;
    border-top: 0px;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include media_query(1000px) {
      padding-inline: 2rem 4rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
}


.title-space{
  padding-left: 1rem;
  @include media_query(1000px) {padding: none}
}


.title {
  font-family: $title-font-family;
  font-size: 2.5rem;
  color: $secondary-accent; //-800;s
  &:hover {
    color: $primary-accent;
  }

  @include media_query(1000px){
    text-align: left;
    font-size: 2rem;
  }
}

.blurb {
  font-family: $blurb-font-family;

}

.site-nav {

  height: 150px;
  // width: 300px;
  align-items: center;
  justify-self: flex-end;
  padding-bottom: 2rem;

  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  @include media_query(1000px){
  }

  li {
    list-style: none;
    padding: 1rem;
  }

  a {
    // font-family: $header-font-family;
    color: $secondary-accent;
    text-decoration: none;
    font-weight: 800;
    font-size: 1.25rem;
    text-align: center;
    text-transform: capitalize;


    &:hover {
      color: $primary-accent;
    }
  }
}
