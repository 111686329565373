.center {
  text-align: center;
}

.highlight {
  color: $tertiary-accent;
}

.hidden {
  display: none;
}