.support-sticky {
  position: fixed;
  z-index: 20;

  filter: invert(75%);
  height: 40px;
  width: 40px;
  bottom: 5%;
  right: 10%;

  @include media_query(1000px){
  right: 2%;
}

  &:hover {
    scale: 150%;
    filter: brightness(0) saturate(100%) invert(52%) sepia(86%) saturate(457%) hue-rotate(4deg) brightness(93%) contrast(90%);
    
    &::after {
      content: ""
    }
    
  }
  
  &::after {
    content: "Support";
  }
}
