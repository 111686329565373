body {
  // background-color: rgba($background-color, 95%);
  background-color: $background-color;

  color: $text-color;
  font-family: $body-font-family;
  font-size: 1.05rem;

  p {
    margin: 0.5rem 1rem;
  }

  h1 {
    font-size: 2rem;
    text-align: center;
    font-family: $header-font-family;
    &::after {
      content: "";
      display: block;
      width: 85%;
      height: 1px;
      background-color: white;
      margin-inline: auto;
      margin-bottom: 1rem;
    }
  }


  .page-content {
    width: 100%;
    padding: 1rem;
    border: solid 1px;
    border-radius: 10px;
    background-color: rgba(black, 35%);
    
    @include media_query (1000px){
      padding: 2rem;

    }
    
 

  }

  .wrapper {
    margin: 1rem auto;
    
    @include media_query(1000px){
      width: 90%;
    }
    
  }

  h2 {
    font-size: 1.5rem;
    margin-top: 1.5rem;
  }

  h3{
    font-size: 1.15rem;
    // font-weight: bold;
    text-decoration-line: underline;

  }

}

a {
  color: $link-color;
  text-decoration: none;
 
  &:hover{
    color: $link-hover-color;
    filter: brightness(280%);
  }
}

.container {
  display: grid;
  min-height: 100%;
  
  margin-top: 3rem;

  @include media_query(1000px) {
    grid-template-columns: 2fr 5fr 2fr;
  }
}


.post-title {
  color: white;
}


// Adding 'Contents' headline to the TOC
#markdown-toc::before {
  content: "Contents";
  font-weight: bold;
}


// Using numbers instead of bullets for listing
#markdown-toc ul {
  list-style: decimal;
}

#markdown-toc {
  padding: 1.5em 2em;
  list-style: decimal;
  
  
  @include media_query(1000px){
    border: 1px solid #aaa;
    border-radius: 10px;
    position: fixed;
    left: 2%;
    width: 18%;
    min-width: 100px;
  }
}


.dt{
  color: $tertiary-accent;
  font-size: 1.25rem;
}

.prev-next{
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding-inline: 1.5rem;
  width: 100%;

}

.next-link {
  justify-content: flex-end;
}


#embedLink {
    &:hover {
      scale: 1.10;
    }
  }
