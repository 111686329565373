@charset "utf-8";


@font-face {
  font-family: DancingScript;
  src: url("../fonts/Dancing_Script/static/DancingScript-Regular.ttf");
}

@font-face {
  font-family: Kalam;
  src: url("../fonts/Kalam/Kalam-Regular.ttf");
}

@font-face {
  font-family: Shadows_Into_Light;
  src: url("../fonts/Shadows_Into_Light/ShadowsIntoLight-Regular.ttf");
}

@font-face {
  font-family: Courgette;
  src: url("../fonts/Courgette/Courgette-Regular.ttf");
}

@font-face {
  font-family: Rowdies;
  src: url("../fonts/Rowdies/Rowdies-Regular.ttf");
}

@font-face {
  font-family: Philosopher;
  src: url("../fonts/Philosopher/Philosopher-Regular.ttf");
}

@font-face {
  font-family: PassionOne;
  src: url("../fonts/Passion_One/PassionOne-Regular.ttf");
}

@font-face {
  font-family: SairaCondensed;
  font-weight: 400;
  src: url("../fonts/Saira_Condensed/SairaCondensed-Medium.ttf");
}

@font-face {
  font-family: Staatliches;
  src: url("../fonts/Staatliches/Staatliches-Regular.ttf");
}

@font-face {
  font-family: KaushanScript;
  src: url("../fonts/Kaushan_Script/KaushanScript-Regular.ttf");
}



// Define defaults for each variable.

$base-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$base-font-size: 1rem !default;
$base-font-weight: 400 !default;
$small-font-size: $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;


$title-font-family: KaushanScript;
$header-font-family: Staatliches;
$blurb-font-family: Kalam;
$body-font-family: SairaCondensed;
$written-font-family: Kalam;

$spacing-unit: 30px !default;

// $primary-accent: #3a0950;
$primary-accent: #2B3847;
$secondary-accent: #c48e13;
// $tertiary-accent: #FAA077; A6FD9C C39AEE 7CD364
$tertiary-accent: #C39AEE;


$neutral-200: #e2e0e0;
$neutral-300: #c7c7c7;
$neutral-800: #1a1a1a;
$neutral-900: #0a0a0a;

$text-color: $neutral-300 !default;

$background-color: #181f27 !default;//light theme
// $background-color: #0b030d !default; //dark theme
$link-color: $secondary-accent;
$link-hover-color: $primary-accent;

$table-text-align: left !default;

// Width of the content area
$content-width: 800px !default;

$on-palm: 600px !default;
$on-laptop: 800px !default;


//Allow toggle
@media (prefers-color-scheme: light) {
  $primary-accent: #2B3847; 
  $text-color: $neutral-800 !default;
  $background-color: #181f27 !default;
}

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
// @mixin media-query($device) {
//   @media screen and (max-width: $device) {
//     @content;
//   }
// }
//
// @mixin relative-font-size($ratio) {
//   font-size: $base-font-size * $ratio;
// }

@mixin media_query($min-width: 1024px) {
  @media(min-width: $min-width) {
    @content;
  }
}

// Resets

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

strong, b{
  font-weight: 900;
}

em{
  font-style: italic;
}
/* Remove default margin */
* {
  margin: 0;
  font: inherit;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

/* Set core body defaults */
body {
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture,
video,
canvas,
svg {
  max-width: 100%;
  display: block;
}



/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

/// Imports

@import 
"_header",
"_layout",
"_footer",
"_support",
"_markdown-classes",
"_glossary-tooltip"
;
