.site-footer {
  background-color: $neutral-900;
  color: rgba($neutral-200, 75%);
  padding: 0.75rem 2rem;

  font-weight: bold;

  margin-top: 3rem;

}

.footer-items {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;

  @include media_query(1000px) {
    flex-direction: row;
  }
}

.credit-dessence {
  font-weight: 100;
  min-width: 11rem;

  font-size: 0.8rem;
  margin-top: 1rem;
  align-self: center;
}
